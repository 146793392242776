import React, { useContext } from 'react';
import { MDBNavbar } from 'mdb-react-ui-kit';
import { ThemeContext } from './Theme'
import { NavbarContent } from './Navbar'

import Logo from '../images/logo128.png';

function CreateNavbar(props) {

    const { darkMode } = useContext(ThemeContext);

    return (
        darkMode ? <MDBNavbar expand='lg' dark bgColor='dark' sticky>{props.children}</MDBNavbar> : <MDBNavbar expand='lg' light bgColor='light' sticky>{props.children}</MDBNavbar>
    );
}

function NavbarWithHeader(props) {

    const { genZ } = useContext(
        ThemeContext
    );

    const { background } = useContext(ThemeContext);

    return (
        <div>
            <header>
                <CreateNavbar>
                    <NavbarContent />
                </CreateNavbar>

                <div id='header' className='p-5 text-center bg-image' style={{ backgroundImage: 'url(' + (background != undefined ? background.urls.full : "") + ')', height: 400 }}>
                    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div className='text-white'>
                                <img id='logo' src={Logo} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' />
                                <h1 className='mb-3'>IngsocDev</h1>
                                {genZ === false ? (<h5 className='mb-4'>Intelligent Software Solutions for Your Business</h5>) :
                                    (<h5 className='mb-4'>We Make Non-Corny Software That Slaps 🔥</h5>)}
                                <a className='btn btn-outline-light btn-lg m-2'
                                    rel='noreferrer'
                                    href='https://linkedin.com/in/tomthehuman'
                                    role='button'
                                    target='_blank'>LinkedIn
                                </a>
                                <a className='btn btn-outline-light btn-lg m-2'
                                    rel='noreferrer'
                                    href='https://tombrown.io'
                                    target='_blank'
                                    role='button'>tombrown.io
                                </a>
                                <a className='btn btn-outline-light btn-lg m-2'
                                    rel='noreferrer'
                                    href='https://gitlab.tombrown.io/public-projects'
                                    target='_blank'
                                    role='button'>Gitlab
                                </a>
                                <a className='btn btn-outline-light btn-lg m-2'
                                    rel='noreferrer'
                                    href='https://gethireable.com'
                                    target='_blank'
                                    role='button'>Hireable
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

function App() {
    return (
        <NavbarWithHeader />
    );
}

export default App;
