import React, { useContext } from 'react';
import { MDBCardTitle, MDBTooltip, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBCardFooter, MDBTypography } from 'mdb-react-ui-kit';
import Slider from 'react-slick';
import FadeIn from 'react-fade-in';

import { Theme, ThemeContext } from '../components/Theme'
import NavbarWithHeader from '../components/NavbarWithHeader'
import Footer from '../components/Footer'
import Contact from '../components/Contact'

import User from '../images/user.png';
import Profile from '../images/profile.jpg';
import Star from '../images/star.png';
import LeftQuote from '../images/left-quote.png';
import RightQuote from '../images/right-quote.png';
import Cassette from '../images/cassette.png';
import George from '../images/george.jpg';
import Ryan from '../images/ryan.jpg';

import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';

function ThemedSlider(props, width, customClass) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const { darkMode } = useContext(
    ThemeContext
  );

  return (
    {
      ...darkMode ?
        <MDBRow id='slider-light' className={`mb-4 d-flex justify-content-center ${props.customClass}`}>
          <Slider style={{ width: props.width }}  {...settings}>
            {props.children}
          </Slider>
        </MDBRow>
        :
        <MDBRow id='slider-dark' className={`mb-4 d-flex justify-content-center ${props.customClass}`}>
          <Slider style={{ width: props.width }}  {...settings}>
            {props.children}
          </Slider>
        </MDBRow>
    }
  );
}

function Home() {
  const { darkMode, genZ } = useContext(
    ThemeContext
  );

  return (
    <div>
      <NavbarWithHeader />
      <MDBContainer fluid className='app_content'>
        <FadeIn delay={600}>
          <MDBRow className='g-3 pb-3 pt-3'>
            <MDBCol xl='3'>
              <MDBCard className='text-center' style={darkMode ? { width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)' } : { width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                <MDBCardBody>
                  <MDBCardTitle className={darkMode ? 'text-light display-6 mx-2' : 'display-6 mx-2'}><img src={User} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' />{' '}{genZ === false ? ("About Us") : ("Who Dis?")}</MDBCardTitle>
                  <MDBCardText className={darkMode ? 'mt-4 mx-2 mb-4 text-light' : 'mt-4 mx-2 mb-4'}>
                    <MDBCardText className={darkMode ? "text-light" : ""}>
                      {genZ === false ? (
                        <MDBTypography className='lead'>IngsocDev is a Software Development company run by <a href="https://tom.lgbt">Tom</a>, a former UK Space / Defence contractor and current Software Engineer at <a href='https://mettle.co.uk'>Mettle</a> and part-time CTO at <a href='https://gethireable.com'>Hireable</a>, an AI startup. We have developed production software sold and used by thousands of customers for <a href="https://superraregames.com/">Super Rare Games</a>.</MDBTypography>)
                        : (<MDBTypography className='lead'>IngsocDev is a Software biz run by <a href="https://tom.lgbt">Tom</a> 🏳️‍🌈👨‍💻 creating software that highkey slaps. Tom did Space 🚀 / Defence 🛡️ when he was a fresh kid but now he ✨vibes✨ with <a href='https://mettle.co.uk'>Mettle</a> building a fresh and non-corny banking platform 🏦 while moonlighting as part-time CTO at <a href='https://gethireable.com'>Hireable</a>, an AI startup 🤖. Tom also vibes with <a href="https://superraregames.com/">Super Rare</a> on the side to get that bag 💰.</MDBTypography>)}
                    </MDBCardText>
                  </MDBCardText>
                  <div className='text-center'>
                    <a href='https://linkedin.com/in/tomthehuman' target='_blank' rel='noreferrer'><img id='profile' src={Profile} style={{ height: '115px', width: '115px' }} className='img-fluid' alt='...' /></a>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xl='9'>
              <MDBCard className='text-center' style={darkMode ? { width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)' } : { width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                <MDBCardBody>
                  <MDBCardTitle className={darkMode ? 'text-light display-6 mx-2' : 'display-6 mx-2'}><img src={Star} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' />{' '}{genZ === false ? ("Testimonials") : ("Good Vibes From Fly Guys")}</MDBCardTitle>
                  <ThemedSlider width={'100%'} customClass={'testimonials'}>
                    <div>
                      <MDBCardText className={darkMode ? 'mx-2 text-light' : 'mx-2'}>
                        <MDBTypography className='display-6 mb-4'><img src={LeftQuote} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' />{' '}We contracted Tom to create a custom launcher for our new project - the Super Rare Mixtape. He worked with our team incredibly well and the final product was amazing! Would thoroughly recommend him!{' '}<img src={RightQuote} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' /></MDBTypography>
                        <figcaption className='blockquote-footer mb-0'>George Perkins - <cite title='Source Title'>Head of Doing Stuff at Super Rare Games</cite></figcaption>
                        <div className='text-center mt-4'>
                          <a href='https://www.linkedin.com/in/george-perkins' target='_blank' rel='noreferrer'><img id='profile' src={George} style={{ height: '115px', width: '115px' }} className='img-fluid' alt='...' /></a>
                        </div>
                      </MDBCardText>
                    </div>
                    <div>
                      <MDBCardText className={darkMode ? 'mx-2 text-light mt-4' : 'mx-2 mt-4'}>
                        <MDBTypography className='display-6 mb-4'><img src={LeftQuote} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' />{' '}Ultimately, the app, which was deemed from the start to be a critical selling point of the product, goes above and beyond its concept.{' '}<img src={RightQuote} style={{ height: '52px', width: '52px' }} className='img-fluid' alt='...' /></MDBTypography>
                        <figcaption className='blockquote-footer mb-0'>Ryan Brown - <cite title='Source Title'>Head of Saying Stuff at Super Rare Games</cite></figcaption>
                        <div className='text-center mt-5'>
                          <a href='https://www.linkedin.com/in/ryan-brown-83507467' target='_blank' rel='noreferrer'><img id='profile' src={Ryan} style={{ height: '115px', width: '115px' }} className='img-fluid' alt='...' /></a>
                        </div>
                      </MDBCardText>
                    </div>
                  </ThemedSlider>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow className='g-3 pb-3'>
            <MDBCol xl='8'>
              <MDBCard style={darkMode ? { width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)' } : { width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                <MDBCardBody>
                  <MDBCardTitle className={darkMode ? 'text-light display-6 mx-2 text-center' : 'display-6 mx-2 text-center'}><img src={Cassette} style={{ height: '55px', width: '55x' }} className='img-fluid' alt='...' />{' '}Super Rare Mixtape</MDBCardTitle>
                  <MDBCardText className={darkMode ? 'mt-4 mx-2 text-light' : 'mt-4 mx-2'}>
                    {genZ === false ? (<MDBTypography className='lead mb-0'>Step into the world of the extraordinary Super Rare Mixtape – an enticing compilation of 30 top-tier indie games thoughtfully packed into a sleek USB cassette, offering instant playability right out of the box. Yet, this is no ordinary mixtape; it's a one-of-a-kind masterpiece meticulously crafted by our skilled team at IngsocDev.
                    Using C#.Net, we've fashioned tailor-made UI components that seamlessly cater to the mixtape's unique requirements. Unsurprisingly, the Super Rare Mixtape has taken the games industry by storm. It has garnered attention from industry giants like <a href='https://www.ign.com/articles/super-rare-mixtape-vol-1-indie-collection-physical-30-games'>IGN</a>, as well as dedicated YouTubers, creating quite a buzz around this limited edition release. 
                    <br /><br />The response is well-deserved, given that when it hit the market on August 19th, 2021, all 2000 units were snapped up within a mere 24 hours. But that's just the beginning of this exciting journey. Buoyed by the resounding success of the inaugural edition, we at IngsocDev have continued to craft exceptional mixtapes. With the launch of Volume 1, 2, 3, and 4, along with a special horror edition in October 2023, we've expanded our offerings.
                    So, if you missed out on the early rounds, don't lose hope – you'll have multiple opportunities to get your hands on these coveted collections of indie gaming excellence.<br /><br /></MDBTypography>)
                      : (<MDBTypography className='lead mb-0'>🚀 Yo, get strapped in for the wild ride of the Super Rare Mixtape – a lit compilation of 30 indie games jam-packed into a sizzling USB cassette. It's like, "Plug and Play, baby!" 🎮🔥 But peep this – it's not your grandma's mixtape; it's a straight-up masterpiece crafted by the IngsocDev crew. 💪 They used C#.Net to cook up custom UI magic, tailor-fitted to the mixtape's unique swag. 🎨💻
                      No cap, the Super Rare Mixtape's got the gaming world lit AF! 🌟 It's got the big dogs like <a href='https://www.ign.com/articles/super-rare-mixtape-vol-1-indie-collection-physical-30-games'>IGN</a> and the YouTubers all shook, causing a major frenzy for this exclusive drop.<br /><br /> 🤩 And why not – when it hit the streets on August 19, 2021, all 2000 units got snagged in just 24 hours! 😱🕒
                      But hold up, that's just the start of this wild ride. IngsocDev already dropped 5 Mixtapes – Volumes 1 to 4 plus a spooky horror edition in October 2023! 🚀🔥 So if you missed the early waves, no worries – more chances are coming your way to score these epic indie game collections. 🎮🤑💯🤙<br /><br /></MDBTypography>)}
                  </MDBCardText>
                  <ThemedSlider width={'50%'} customClass={'mixtape'}>
                    <div className='ratio ratio-16x9'>
                      <iframe src='https://www.youtube.com/embed/BcP11h7RGVA' title='Super Rare Mixtape Horror Edition' allowfullscreen></iframe>
                    </div>
                    <div className='ratio ratio-16x9'>
                      <iframe src='https://www.youtube.com/embed/TFe7gVy504Q' title='#SRGMIXTAPE { INCOMING TRANSMISSION }' allowfullscreen></iframe>
                    </div>
                    <div className='ratio ratio-16x9'>
                      <iframe src='https://www.youtube.com/embed/Pdll8rqY-AU' title='Super Rare Mixtape Vol. 1 📼 Behind the Mixtape' allowfullscreen></iframe>
                    </div>
                  </ThemedSlider>

                  <MDBTypography className='text-center'><br />📼 <a href="https://superraregames.com/products/srg-mixtape-volume-1">Mixtape Vol 1 📼</a> <a href="https://superraregames.com/products/srg-mixtape-volume-2">Mixtape Vol 2</a> 📼 <a href="https://superraregames.com/products/srg-mixtape-volume-3">Mixtape Vol 3</a> 📼 <a href="https://superraregames.com/products/srg-mixtape-volume-4">Mixtape Vol 4</a> 🎃 <a href="https://superraregames.com/products/super-rare-mixtape-horror-edition">Mixtape Horror Edition</a><br /></MDBTypography>
                </MDBCardBody>
                <MDBCardFooter className='text-center'>
                  <MDBTooltip wrapperProps={{ href: 'https://superraregames.com/products/srg-mixtape-volume-1', target: '_blank', className: 'mx-2', color: darkMode ? 'dark' : 'primary' }} placement='bottom' title='Product Page'>
                    <MDBIcon fas icon='shopping-cart' />{' '}Product Page
                  </MDBTooltip>
                  <MDBTooltip wrapperProps={{ href: 'https://twitter.com/i/status/1426993776132829219', target: '_blank', className: 'mx-2', color: darkMode ? 'dark' : 'primary' }} placement='bottom' title='Demo Video'>
                    <MDBIcon fas icon='video' />{' '}Demo Video
                  </MDBTooltip>
                </MDBCardFooter>
              </MDBCard>
            </MDBCol>
            <MDBCol xl='4'>
              <Contact />
            </MDBCol>
          </MDBRow>
        </FadeIn>
      </MDBContainer>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Theme showBackground={false}>
      <Home themeContext={ThemeContext} />
    </Theme >
  );
}

export default App;
