import React, { useEffect, useState, useContext } from "react";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { MDBTooltip, MDBIcon } from "mdb-react-ui-kit";
import { createApi } from 'unsplash-js';
import { Tracking } from "./Tracking";

const ThemeContext = React.createContext();
const unsplash = createApi({ accessKey: 'zugxSOP9glh9FDwV-frCD5HYJDt5kCV_CcxA2SkcOkU' });

async function getRandomPhotoFromUnsplash(collectionIds) {
  try {
    const result = await unsplash.photos.getRandom({
      featured: true,
      collectionIds: collectionIds,
      count: 1,
    });

    if (result.errors) {
      console.error('Error occurred:', result.errors[0]);
      return null;
    } else {
      const photo = result.response[0];
      return photo;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
}

function DarkModeToggle() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  let icon = darkMode ? (<MDBIcon className="text-warning" far icon="lightbulb" size="2x" />)
    : (<MDBIcon className="text-warning" fas icon="lightbulb" size="2x" />);

  return (
    <div><MDBTooltip wrapperProps={{ className: "mx-2", onClick: toggleDarkMode, color: "transparent" }} placement="bottom" title="Toggle Dark Mode">{icon}</MDBTooltip></div>
  );
}

function GenZModeToggle() {
  const { toggleGenZ } = useContext(ThemeContext);

  return (
    <div>
      <MDBTooltip wrapperProps={{ className: "mx-2", onClick: toggleGenZ, color: "transparent" }} placement="bottom" title="Toggle Gen Z Language">
        <MDBIcon className="text-warning" fas icon="fire-alt" size="2x" />
      </MDBTooltip>
    </div>
  );
}

function Theme(props) {
  var currentHour24 = new Date().getHours();

  const [darkMode, setDarkMode] = useState(
    currentHour24 >= 21 || currentHour24 <= 8
  );
  const [genZ, setGenZ] = useState(true);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    const setBackgroundPhoto = async () => {
      const query = darkMode ? ['401922', '3468132', '41020635'] : ['mCxOIZM8G8A', '256524', '1525589', '361687', '148982'];
      const photo = await getRandomPhotoFromUnsplash(query);
      setBackground(photo);
    };

    setBackgroundPhoto();
  }, [darkMode]);

  function toggleGenZ() {
    setGenZ((genZ) => !genZ);
  }

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  return (
    <div>
      <ThemeContext.Provider value={{ darkMode, genZ, background, toggleDarkMode, toggleGenZ }}>
        {props.showBackground === undefined ? (
          <BackgroundImage src={background != undefined ? background.urls.full : ""} className="App">
            <Tracking themeContext={ThemeContext} />
            {props.children}
          </BackgroundImage>
        ) : (props.children)}
      </ThemeContext.Provider>
    </div>
  );
}

export { Theme, DarkModeToggle, GenZModeToggle, ThemeContext };
