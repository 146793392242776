import React, { useState } from 'react';
import { DarkModeToggle, GenZModeToggle } from './Theme'
import { MDBNavbarToggler, MDBNavbar, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBNavbarBrand, MDBIcon, MDBContainer } from 'mdb-react-ui-kit';

import Logo from '../images/logo128.png';

function NavbarContent() {
    const [showBasic, setShowBasic] = useState(false);

    return (
        <MDBContainer fluid>
            <MDBNavbarBrand href='/'>
                <img id='logo' src={Logo} height='30' alt='' />
            </MDBNavbarBrand>

            <MDBNavbarToggler aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation' onClick={() => setShowBasic(!showBasic)}>
                <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar show={showBasic}>
                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                    <MDBNavbarItem>
                        <MDBNavbarLink href='/'>Home</MDBNavbarLink>
                    </MDBNavbarItem>
                </MDBNavbarNav>
            </MDBCollapse>
            <GenZModeToggle />
            <DarkModeToggle />
        </MDBContainer>
    )
}

function Navbar(props) {
    return (
        <div>
            <MDBNavbar style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} expand='lg' dark>
                <NavbarContent />
            </MDBNavbar>
        </div>
    );
}

export { Navbar, NavbarContent };
