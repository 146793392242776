import React, { useContext } from "react";
import { MDBFooter, MDBTypography } from 'mdb-react-ui-kit';
import { ThemeContext } from "./Theme";

function Footer() {
  const { background } = useContext(ThemeContext);
    return (
        <MDBFooter backgroundColor='light' className='text-center text-lg-left'>
            <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <MDBTypography tag='small' className='text-muted'>
                    &copy; {new Date().getFullYear()} - IngsocDev made with React.js in <i className='united kingdom flag'></i>
                     - Icons made by bsd, SumberRejeki, <a href='https://www.flaticon.com/authors/freepik' title='Freepic'>Freepic</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a><a href='https://www.freepik.com/vectors/background'>, background vector created by starline - www.freepik.com</a>
                     - Header background by {background != undefined ? <a href={background.user.links.html + "?utm_source=ingsoc.dev&utm_medium=referral"}>{background.user.first_name + " " + background.user.last_name}</a> : ""} on <a href="http://www.unsplash.com/">Unsplash</a>
                </MDBTypography>
            </div>
        </MDBFooter>
    );
}

function App() {
    return (
        <Footer />
    );
}

export default App;
